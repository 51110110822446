<template>
  <!-- pb-px fixes disappearing border bottom on some resolutions  -->
  <div
    class="overflow-x-auto pb-px"
    :class="isStyled ? `base-table--${props.styleVariant}` : ''"
    data-el="ui-base-table"
  >
    <!-- 1px height on the table allows to set 100% height to inner children -->
    <table
      :class="internalStyles.table.el"
      class="h-px"
    >
      <UiBaseTableHeader
        v-if="props.headers?.length"
        :class="internalStyles.thead.el"
        :sort-field="internalSortField"
        :sort-order="internalSortOrder"
        :headers="props.headers"
        :body="props.body"
        :all-rows-selected="internalIsSelectAllCheckboxSelected"
        :is-checkbox-indeterminate="internalIsSelectAllCheckboxIndeterminate"
        :multiselect="props.multiselect"
        :styles="internalStyles"
        @click:column="handleColumnClick"
        @click:checkbox="handleSelectAllRows"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]="slotData"
        >
          <slot
            :name="name"
            v-bind="{ ...slotData, styles: internalStyles }"
          />
        </template>
      </UiBaseTableHeader>

      <UiBaseTableBody
        :class="internalStyles.tbody.el"
        :body="props.body"
        :selected-rows="selectedRowsValues"
        :multiselect="props.multiselect"
        :expanded-rows="props.expandedRows"
        :highlighted-rows="props.highlightedRows"
        :total-column-count="totalColumnCount"
        :styles="internalStyles"
        :loading="props.loading"
        :row-style="props.rowStyle"
        :row-class="props.rowClass"
        :row-cell-class="props.rowCellClass"
        :row-cell-style="props.rowCellStyle"
        @click:checkbox="handleSelectRowItem"
        @row-toggle="toggleRowExpansion"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]="slotData"
        >
          <slot
            :name="name"
            v-bind="{ ...slotData, styles: internalStyles }"
          />
        </template>
      </UiBaseTableBody>
      <tfoot
        v-if="$slots.footer"
        :class="internalStyles.tfoot.el"
      >
        <tr :colspan="props.body.length">
          <slot name="footer" />
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { defu } from 'defu'
import type {
  SortField,
  SortOrderValues,
  TableCellClickEmitValue,
  UiBaseTableProps,
} from './UiBaseTable.types'
import { SortOrder } from './UiBaseTable.types'
import UiBaseTableHeader from './UiBaseTableHeader.vue'
import UiBaseTableBody from './UiBaseTableBody.vue'
import { useBaseTable } from './useBaseTable'
import { getColumnOrColumnPropValue } from './UiBaseTable.utils'

const props = withDefaults(defineProps<UiBaseTableProps>(), {
  loading: false,
  headers: () => [],
  sortField: null,
  sortOrder: SortOrder.NONE,
  defaultSortOrder: SortOrder.ASCENDING,
  removableSort: false,
  multiselect: false,
  showRowNumber: true,
  expandedRows: () => [],
  selectedRows: () => [],
  highlightedRows: () => [],
  isSelectAllCheckboxSelected: false,
  isSelectAllCheckboxIndeterminate: false,
  styles: () => ({}),
  rowStyle: () => ({}),
  styleVariant: 'none',
})

const emit = defineEmits<{
  (e: 'click:column')
  (e: 'click:select-all-rows', value: string[])
  (
    e: 'click:select-row',
    value: { row: TableCellClickEmitValue, selectedRows: string[] },
  )
  (e: 'update:sort-field', value: SortField)
  (e: 'update:sort-order', value: SortOrderValues)
  (e: 'sort', value: { sortField: SortField, sortOrder: SortOrderValues })
  (e: 'update:expanded-rows', value: string[])
  (e: 'update:selected-rows', value: string[])
  (e: 'update:is-select-all-checkbox-selected', value: boolean)
  (e: 'update:is-select-all-checkbox-indeterminate', value: boolean)
  (e: 'row-collapse', value: TableCellClickEmitValue)
  (e: 'row-expand', value: TableCellClickEmitValue)
}>()

const { updateSelectedRow, selectAllRows, isIndeterminateState } = useBaseTable()

const internalSortField = ref<SortField>(props.sortField)
const internalSortOrder = ref<SortOrderValues>(props.sortOrder)
const internalIsSelectAllCheckboxSelected = ref<boolean>(
  props.isSelectAllCheckboxSelected,
)
const internalIsSelectAllCheckboxIndeterminate = ref<boolean>(
  props.isSelectAllCheckboxIndeterminate,
)
const selectedRowsValues = ref<string[]>(props.selectedRows)

const isStyled = computed(() => {
  return props.styleVariant !== 'none'
})

watch(
  () => props.selectedRows,
  () => {
    internalIsSelectAllCheckboxIndeterminate.value = isIndeterminateState(
      props.selectedRows,
      props.body.length,
    )
    internalIsSelectAllCheckboxSelected.value = Boolean(props.selectedRows.length)
  },
  { immediate: true },
)

watch(
  () => props.body.length,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      emit('update:selected-rows', [])
      internalIsSelectAllCheckboxSelected.value = false
    }
  },
)

watchEffect(() => {
  internalSortField.value = props.sortField
  internalSortOrder.value = props.sortOrder
  selectedRowsValues.value = props.selectedRows
})

function handleSelectAllRows() {
  if (!props.body || !selectedRowsValues.value) {
    return
  }

  const data = selectAllRows(props.body, selectedRowsValues.value)

  if (!data) {
    return
  }

  const {
    selectedRows,
    selectAllCheckboxSelected,
    selectAllCheckboxIndeterminate,
  } = data

  internalIsSelectAllCheckboxSelected.value = selectAllCheckboxSelected
  emit('update:is-select-all-checkbox-selected', selectAllCheckboxSelected)

  internalIsSelectAllCheckboxIndeterminate.value
    = selectAllCheckboxIndeterminate
  emit(
    'update:is-select-all-checkbox-indeterminate',
    selectAllCheckboxIndeterminate,
  )

  selectedRowsValues.value = selectedRows
  emit('click:select-all-rows', selectedRows)
  emit('update:selected-rows', selectedRows)
}

function handleSelectRowItem(data: TableCellClickEmitValue) {
  const {
    selectedRows,
    selectAllCheckboxSelected,
    selectAllCheckboxIndeterminate,
  } = updateSelectedRow(
    selectedRowsValues.value,
    props.body.length,
    data.column.name,
  )

  emit('click:select-row', { row: data, selectedRows })

  internalIsSelectAllCheckboxSelected.value = selectAllCheckboxSelected
  emit('update:is-select-all-checkbox-selected', selectAllCheckboxSelected)

  internalIsSelectAllCheckboxIndeterminate.value
    = selectAllCheckboxIndeterminate
  emit(
    'update:is-select-all-checkbox-indeterminate',
    selectAllCheckboxIndeterminate,
  )

  selectedRowsValues.value = selectedRows
  emit('update:selected-rows', selectedRows)
}

function handleColumnClick(data: TableCellClickEmitValue) {
  const column = data.column
  const columnName = column.name

  const isSortable
    = getBodyColumnOrColumnPropValue(columnName, 'sortable')
    ?? data.column.sortable

  if (!isSortable) {
    return
  }

  if (internalSortField.value === column.name) {
    if (
      props.removableSort
      && internalSortOrder.value * -1 === props.defaultSortOrder
    ) {
      internalSortOrder.value = SortOrder.NONE
      internalSortField.value = null
    }
    else {
      internalSortOrder.value = internalSortOrder.value * -1
    }
  }
  else {
    internalSortOrder.value = props.defaultSortOrder
    internalSortField.value = columnName
  }
  emit('update:sort-field', internalSortField.value)
  emit('update:sort-order', internalSortOrder.value)
  emit('sort', {
    sortField: internalSortField.value,
    sortOrder: internalSortOrder.value,
  })
}

function toggleRowExpansion(data: TableCellClickEmitValue) {
  const { column } = data
  const expandedItems = [...props.expandedRows]
  if (props.expandedRows.includes(column.name)) {
    const columnIndex = props.expandedRows.indexOf(column.name)
    expandedItems.splice(columnIndex, 1)

    emit('update:expanded-rows', expandedItems)
    emit('row-collapse', data)
    return
  }
  expandedItems.push(column.name)
  emit('update:expanded-rows', expandedItems)
  emit('row-expand', data)
}

const totalColumnCount = computed(() => {
  const regularColumns = props.body?.[0]?.value?.length ?? 0
  const additionalColumns = props.multiselect ? 1 : 0

  return regularColumns + additionalColumns
})

const internalStyles = computed(() => {
  const defaultStyle = {
    table: {
      el: 'relative min-w-full leading-normal',
    },
    thead: {
      el: 'border-b-2 border-primary text-center text-xs font-semibold text-primary',
      tr: '',
      th: 'px-4 py-3',
    },
    tbody: {
      el: '',
      tr: 'border-b-2 border-primary pt-4 first:border-t-2',
      td: 'border-b border-blue-100 h-full text-primary  md:border-0 p-3 pl-2 text-center text-sm',
    },
    tfoot: {
      el: 'relative h-full',
      tr: '',
      td: '',
    },
  }

  return defu(props.styles, defaultStyle)
})

function getBodyColumnOrColumnPropValue(columnName: string, propName?: string) {
  return getColumnOrColumnPropValue(props.body, columnName, propName)
}
</script>
